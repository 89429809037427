@charset "UTF-8";
/*
Fonts
*/
/*
Mixins
*/
:root {
  --link-color: #d45605;
  --toolbarHeigh: 56px;
  --navigationColor: #ffff;
  --navigationColorActive: #ffff;
  --navigationBorderColorActive: #d45605;
  --navigationColorHover: #000000;
  --navigationButtonColor: #d45605;
  --frontpageHeight: 270px;
  --backgroundHeight: 250px;
  --footerHeight: 50px;
  --spaceHeight: 50px;
  --border: #d45605;
  --boxShadow: #f8ba7a;
  --rce-mbox-professional: #f8ba7a;
  --background: #d45605;
  --background-gradient: linear-gradient(
    to bottom,
    rgba(254, 157, 5, 100),
    rgba(250, 114, 25, 100)
  );
  --background-message: #ffff;
  --background-form: #ffff;
  --navigationBackgroundOldBrowsers: #d45605;
  --navigationBackgroundOldFF: -moz-linear-gradient(
    45deg,
    #e6c288 0%,
    #fe9d05 52%,
    #fa7219 100%
  );
  /* FF3.6-15 */
  --navigationBackgroundOldChrome: -webkit-linear-gradient(
    45deg,
    #e6c288 0%,
    #fe9d05 52%,
    #fa7219 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  --navigationBackgroundNewBrowsers: linear-gradient(
    45deg,
    #e6c288 0%,
    #fe9d05 52%,
    #fa7219 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  --black: #010000;
  --white: #ffffff;
  --lightGrey: #e6e6e6;
  --darkGrey: #333;
  --red: #ff1755;
  --blue: #1f53cb;
  --btngradient: #fe9d05 0%, #fa7219 100%;
  --activegradient: linear-gradient(to top, #fe9d05 0%, #fa7219 100%);
  --font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-family-alt: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-size: 1.3rem;
  --font-size-sm: 1.1rem;
  --gray-base: #000 ' !default';
  --gray-darker: lighten($gray-base, 13.5%) ' !default';
  --gray-dark: lighten($gray-base, 20%) ' !default';
  /* Chat */ }

html {
  display: flex; }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  background-color: var(--navigationColor);
  background-attachment: fixed; }

br {
  line-height: 28%; }

.mainApp {
  height: 100%;
  position: sticky;
  z-index: 1; }

.withPointer {
  cursor: pointer; }

.greenSpan {
  color: var(--navigationColor); }

.marginTop {
  margin-top: 1.5rem; }

.marginTop-mobile {
  margin-top: 4rem; }

.centered {
  min-width: 300px;
  min-height: 150px;
  padding: 20px;
  width: fit-content;
  height: fit-content; }

.segmentMinHeight {
  min-height: 150px; }

.contactInformation {
  border-style: none;
  border-width: 0px;
  background-image: none;
  color: black;
  padding: 10px; }

.contactInformationDetail {
  background-image: var(--background-gradient);
  height: fit-content; }

.banners {
  margin-top: -5%;
  width: 100%;
  height: 160px; }

.image1 {
  max-height: var(--backgroundHeight); }

a {
  color: var(--link-color); }

.sidebarButtonOpen {
  visibility: hidden;
  color: var(--navigationColor); }
  @media (min-width: 768px) {
    .sidebarButtonOpen {
      visibility: hidden !important; } }

.sidebarButtonClose {
  left: 170px;
  z-index: 1000;
  visibility: hidden;
  color: var(--navigationColor); }
  @media (min-width: 768px) {
    .sidebarButtonClose {
      visibility: hidden !important; } }

.visible {
  visibility: visible; }

.insideContainer {
  justify-self: center;
  background-image: var(--background-gradient); }

.insideContainer__EC {
  justify-self: center;
  background-image: transparent; }

.emergencyContacts {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 65%;
  background-image: var(--background-gradient);
  transform: skewY(18deg);
  transform-origin: top right; }

.rce-mbox .rce-mbox-time {
  color: rgba(0, 0, 0, 0.75); }

.rce-mbox-right .rce-mbox-time {
  color: var(--navigationColor); }

.mobileFlex__div {
  border: 2px solid transparent;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5rem; }

.mobileFlex__div .active {
  box-shadow: 0 8px 6px -5px var(--boxShadow);
  border: 1px solid transparent;
  background-color: transparent; }

.desktopFlex {
  display: flex;
  flex-direction: column; }
  .desktopFlex .active.title {
    border-bottom: 2px solid var(--border); }
  .desktopFlex .desktopFlex__div {
    flex-grow: 1;
    flex-basis: 20%;
    padding: 10px 10px 20px 10px;
    margin-bottom: 20px;
    border: 2px solid var(--border);
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px; }
  .desktopFlex .title {
    font-weight: bold; }
  .desktopFlex .details {
    font-size: 90%;
    line-height: 1.8rem;
    flex-shrink: 0;
    border-radius: 5px;
    width: 98%;
    margin-left: auto;
    margin-right: auto; }
  .desktopFlex .details__content {
    padding: 0.7rem; }

.tabPages {
  margin: 5px;
  border-radius: 5px;
  width: calc(100vw * 1 - 10px); }

.blockContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto; }

.blockContainer__EC {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem; }

.blockContainer__div {
  padding: 10px;
  width: 350px;
  margin: 5px;
  flex-grow: 2;
  border: 1px solid var(--border);
  background-color: var(--navigationColor);
  color: black;
  border-radius: 0.7rem;
  margin-top: 2rem; }
  .blockContainer__div img {
    float: left;
    width: 100%; }

.imageToRightOfDiv {
  text-align: right; }

.linkContainer {
  display: flex;
  padding-top: 5px;
  margin: 0 0 0 10px; }

.linkContainer__source {
  font-size: x-small; }

.itemContainer {
  display: grid;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 5px;
  background-color: var(--navigationColor); }

.text-center {
  text-align: center; }

.login__mobile {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 300%;
  background-image: var(--background-gradient);
  transform: skewY(-18deg);
  transform-origin: top left; }

.login__mobile__border {
  border: 2px solid var(--border);
  background-color: var(--navigationColor);
  padding: 2.5rem;
  margin-top: 5rem;
  position: absolute;
  top: 100%;
  left: 6%;
  right: 6%;
  transform: translateY(-40%);
  height: calc( var(--vh, 1vh) * 100 - var(--toolbarHeigh) - var(--frontpageHeight) - var(--footerHeight) - var(--spaceheight)); }

.login__mobile__button,
.login__desktop__button {
  position: inherit;
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, 50%); }

.login__desktop {
  border: 2px solid var(--border);
  border-radius: 30px;
  width: 40%;
  height: 415px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  box-shadow: 3px 3px 5px 6px var(--boxShadow);
  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  -webkit-box-shadow: 3px 3px 5px 6px var(--boxShadow);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 3px 3px 5px 6px var(--boxShadow);
  /* Firefox 3.5 - 3.6 */ }

.login__desktop__header {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  text-transform: uppercase; }

.login__desktop__border {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: var(--navigationColor); }

.login__form {
  padding: 2rem;
  margin: 0.5rem;
  position: relative;
  max-width: 100%; }

.omadiabetesTitle {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  position: absolute;
  color: var(--navigationColor); }

/* Tablet */
@media only screen and (min-width: 769px) and (max-width: 991px) {
  .ui.container {
    width: 500px !important;
    margin-left: auto !important;
    margin-right: auto !important; }
  .ui.grid.container {
    width: calc(723px + 2rem) !important; }
  .ui.relaxed.grid.container {
    width: calc(723px + 3rem) !important; }
  .ui.very.relaxed.grid.container {
    width: calc(723px + 5rem) !important; }
  .omadiabetesTitle {
    width: 600px;
    left: 50%;
    top: 50%;
    margin-left: -300px;
    margin-top: -20px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    position: absolute;
    color: var(--navigationColor);
    font-size: 300%; } }

.AppLayout {
  display: flex;
  height: calc(100vh - var(--toolbarHeigh)); }

.MessageAreaLayout {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch; }

.HeaderWrapper .header {
  max-width: 50%;
  text-align: center; }

.SendMessageWrapper {
  margin: 0px 0px 0px 10px;
  max-width: 650px;
  /* flex: 2; //hajottaa IE:llä message komponentin */
  padding-bottom: 41px; }

.Messages {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto; }

.messagesController {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  flex-grow: 99999; }

.messages {
  background-color: var(--navigationColor); }

.rce-mbox.rce-mbox-right {
  background: var(--background);
  color: var(--navigationColor); }

.rce-card-right-image {
  background: var(--background); }

.rce-mbox-right-notch {
  fill: var(--background); }

.rce-mbox-professional {
  background-color: var(--rce-mbox-professional); }
  .rce-mbox-professional .rce-mbox-left-notch {
    fill: var(--rce-mbox-professional); }
  .rce-mbox-professional .rce-mbox-right-notch {
    fill: black; }

.rce-mbox-right .rce-mbox-title,
.rce-mbox-right .rce-mbox-title:hover {
  cursor: default;
  color: var(--navigationColor);
  text-decoration: none; }

.rce-mbox-professional .rce-mbox-title,
.rce-mbox-professional .rce-mbox-title:hover {
  cursor: default;
  text-decoration: none; }

.sendMessageWrapper {
  margin: 20px;
  flex: 1;
  bottom: 0;
  flex-shrink: 0; }

.login__mobile {
  height: 180%; }

.ui.text.container {
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  max-width: auto !important;
  line-height: 1.5;
  height: fit-content;
  padding-bottom: 2rem; }

@media (min-width: 768px) {
  .showOnlyOnDesktop {
    display: block; }
  .showOnlyOnMobile {
    display: none; } }

@media (min-width: 768px) and (min-height: 400px) {
  .showOnlyOnDesktop {
    display: block; }
  .showOnlyOnMobile {
    display: none; } }

@media (max-width: 768px) {
  .showOnlyOnDesktop {
    display: none; }
  .showOnlyOnMobile {
    display: block; } }

@media (max-width: 900px) and (max-height: 400px) {
  .showOnlyOnDesktop {
    display: none; }
  .showOnlyOnMobile {
    display: block; } }

@media (min-width: 768px) {
  .login__mobile {
    height: 300%; }
  .omadiabetesTitle {
    font-size: 250%;
    margin-top: 2rem; }
  .login__mobile__border {
    margin-top: 10rem;
    width: 200px; }
  .emergencyContacts {
    height: 90%; }
  .banners {
    margin-top: 0;
    width: 100%;
    height: 300px; } }
