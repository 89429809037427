.rce-mbox-photo {
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
}

.rce-mbox-photo .rce-mbox-text {
    padding: 5px 0px;
    max-width: 300px;
    margin: auto;
}

.rce-mbox-photo--img {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 5px;
    max-height: 300px;
}

.rce-mbox-photo--img__block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
}

.rce-mbox-photo--img img {
    height: 100%;
    min-height: 100px;
    user-select: none;
}

.rce-mbox-photo--img__block-item {
    margin: auto;
    cursor: pointer;
    width: 100px;
    height: 100px;
}

.rce-mbox-photo--download {
    color: #efe;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    font-size: 3.2em;
    outline: none;
    border: 1px solid #eee;
    border-radius: 100%;
    height: 100px;
    width: 100px;
}

.rce-mbox-photo--download:hover {
    opacity: .7;
}

.rce-mbox-photo--download:active {
    opacity: .3;
}

.rce-mbox-photo--error {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: 70px;
    color: #eaeaea;
}
