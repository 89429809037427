/* Semantic.css  */
/*-------------------
        Links
--------------------*/

a {
  color: #fa7219;
  text-decoration: none;
}

a:hover {
  color: #fe9d05;
  text-decoration: none;
}

/*******************************
            Button
*******************************/
.ui.button {
  background: var(--navigationButtonColor);
  color: #fff;
}

/*******************************
            States
*******************************/

/*--------------
      Hover
---------------*/

.ui.button:hover {
  background-color: #fe9d05;
}

/*--------------
      Focus
---------------*/

.ui.button:focus {
  background-color: #fe9d05;
}

/*--------------
      Down
---------------*/

.ui.button:active,
.ui.active.button:active {
  background-color: #fe9d05;
}

/*-------------------
      Or Buttons
--------------------*/

.ui.buttons .or:before {
  color: rgba(0, 0, 0, 0.55);
}

/*******************************
            Types
*******************************/

/* Text Container */

.ui.text.container {
  max-width: auto !important;
  height: fit-content;
}

/*******************************
            Header
*******************************/

/* Standard */

.ui.header {
  text-transform: uppercase;
}

/* Linked Item Icons */

.ui.list .list > a.item i.icon,
.ui.list > a.item i.icon {
  color: rgba(0, 0, 0, 0.55);
}

/*-------------------
  Link
--------------------*/

.ui.link.list .item,
.ui.link.list a.item,
.ui.link.list .item a:not(.ui) {
  color: rgba(0, 0, 0, 0.55);
}

/*-------------------
      Selection
--------------------*/

.ui.selection.list .list > .item,
.ui.selection.list > .item {
  color: rgba(0, 0, 0, 0.55);
}

/*******************************
            Segment
*******************************/

.ui.segment {
  background: initial;
  -webkit-box-shadow: initial;
  box-shadow: initial;
}

/* Vertical */

.ui.vertical.segment {
  border: initial;
  border-bottom: 1px solid transparent;
}

/*******************************
          Content
*******************************/

/* Divider */

.ui.breadcrumb .divider {
  color: rgba(0, 0, 0, 0.55);
}

/* This is added manually */
@media (min-width: 768px) {
  .ui.text.container {
    max-width: 500px !important;
  }
}

/* Computer */

@media only screen and (min-width: 992px) {

  /* This is added manually */
  .ui.text.container {
    max-width: fit-content !important;
  }
}

/*******************************
            Message
*******************************/

.ui.message {
  margin: 1em 1em;
}

/*--------------
    Definition
---------------*/

.ui.definition.table thead:not(.full-width) th:first-child {
  color: rgba(0, 0, 0, 0.55);
}

/*--------------
      Meta
---------------*/

.ui.cards > .card .meta,
.ui.card .meta {
  color: rgba(0, 0, 0, 0.55);
}

/* Meta */

.ui.cards > .card .meta > a:not(.ui),
.ui.card .meta > a:not(.ui) {
  color: rgba(0, 0, 0, 0.55);
}

/*----------------
  Extra Content
-----------------*/

.ui.cards > .card > .extra,
.ui.card > .extra {
  color: rgba(0, 0, 0, 0.55);
}

.ui.cards > .card > .extra a:not(.ui),
.ui.card > .extra a:not(.ui) {
  color: rgba(0, 0, 0, 0.55);
}

/*--------------
    Metadata
---------------*/

.ui.comments .comment .metadata {
  color: rgba(0, 0, 0, 0.55);
}

/*--------------------
    User Actions
---------------------*/

.ui.comments .comment .actions a {
  color: rgba(0, 0, 0, 0.55);
}

/*--------------
      Date
---------------*/

.ui.feed > .event > .content .date {
  color: rgba(0, 0, 0, 0.55);
}

/*--------------
    Inline Date
---------------*/

/* Date inside Summary */

.ui.feed > .event > .content .summary > .date {
  color: rgba(0, 0, 0, 0.55);
}

/* Meta */

.ui.items > .item .meta > a:not(.ui) {
  color: rgba(0, 0, 0, 0.55);
}

/*----------------
  Extra Content
-----------------*/

.ui.items > .item .extra {
  color: rgba(0, 0, 0, 0.55);
}

/*******************************
            Types
*******************************/

/*--------------
    Styled
---------------*/

.ui.styled.accordion .title,
.ui.styled.accordion .accordion .title {
  color: rgba(0, 0, 0, 0.55);
}

.ui.styled.accordion .accordion .title:hover,
.ui.styled.accordion .accordion .active.title {
  background: white;
}

/* Active */

.ui.styled.accordion .active.title {
  background: #f8caab;
}

/*--------------
    Slider
---------------*/

/* Label */

.ui.slider.checkbox .box,
.ui.slider.checkbox label {
  color: rgba(0, 0, 0, 0.55);
}

/*-----------------
  Item Description
-------------------*/

.ui.dropdown > .text > .description,
.ui.dropdown .menu > .item > .description {
  color: rgba(0, 0, 0, 0.55);
}

/*-----------------
      Message
-------------------*/

.ui.dropdown .menu > .message:not(.ui) {
  color: rgba(0, 0, 0, 0.55);
}

/*-------------------
      Responsive
--------------------*/

/* Modal Width */

@media only screen and (max-width: 767px) {
  .ui.modal {
    width: auto;
    max-width: 95%;
  }
}

@media only screen and (min-width: 768px) {
  .ui.modal {
    width: auto;
    max-width: 88%;
  }
}

@media only screen and (min-width: 992px) {
  .ui.modal {
    width: auto;
    max-width: 850px;
  }
}

@media only screen and (min-width: 1200px) {
  .ui.modal {
    width: auto;
    max-width: 900px;
  }
}

@media only screen and (min-width: 1920px) {
  .ui.modal {
    width: auto;
    max-width: 950px;
  }
}

/*--------------
    White
---------------*/

.ui.inverted.nags .nag .close,
.ui.inverted.nags .nag .title,
.ui.inverted.nag .close,
.ui.inverted.nag .title {
  color: rgba(0, 0, 0, 0.55);
}

/*******************************
            Popup
*******************************/

.ui.popup {
  border: 2px solid transparent;
  border-radius: 10px;
  width: 250px;
}

/*--------------
      Info
---------------*/

.ui.search > .results .result .description {
  color: rgba(0, 0, 0, 0.55);
}

/* Category Result Name */

.ui.category.search > .results .category > .name {
  color: rgba(0, 0, 0, 0.55);
}

/*--------------
    Dimmer
---------------*/

.pushable > .pusher:after {
  background-color: rgba(0, 0, 0, 0.55);
}

/* **** accordion.css */

/*--------------
      Styled
---------------*/

.ui.styled.accordion,
.ui.styled.accordion .accordion {
  background: white;
}
