// COLORS

$shadow: unquote('rgba(0, 0, 0, 0.5803921568627451)');
$shadow-light: unquote('rgba(0, 0, 0, 0.3215686274509804)');

// FONTS

$font-xs-italic: italic 12px 'Segoe UI', 'Lucida Grande', Arial, sans-serif;
$font-xs-bold: bold 12px 'Segoe UI', 'Lucida Grande', Arial, sans-serif;
$font-s: normal 14px 'Segoe UI', 'Lucida Grande', Arial, sans-serif;
$font-m: normal 16px 'Segoe UI', 'Lucida Grande', Arial, sans-serif;
$font-m-bold: bold 15px 'Segoe UI', 'Lucida Grande', Arial, sans-serif;
$font-l-bold: bold 18px 'Segoe UI', 'Lucida Grande', Arial, sans-serif;

// $font-xs-italic: italic 12px 'Muli', sans-serif;
// $font-xs-bold: bold 12px 'Muli', sans-serif;
// $font-s: normal 14px 'Muli', sans-serif;
// $font-m: normal 16px 'Muli', sans-serif;
// $font-m-bold: bold 16px 'Muli', sans-serif;
// $font-l-bold: bold 18px 'Muli', sans-serif;

// BREAKPOINTS

$small: 320px;
$medium: 768px;
$large: 1024px;

@mixin modal {
  width: 300px;
  height: auto;
  margin: auto;
  margin-top: 100px;
  border-radius: 6px;
  outline: none;

  form {
    width: 220px;
    margin: auto;
    text-align: center;
    border: 1px solid transparent;

    h3 {
      margin: 15px auto 20px auto;
      font: $font-l-bold;
    }

    .error {
      height: 20px;
      margin: 20px auto;
      font: $font-s;
      text-align: center;
    }

    p {
      margin: 5px auto;
      font: $font-s;
      text-align: left;
    }

    input {
      width: 220px;
      margin: 0 auto 20px auto;
      background: transparent;
      font: $font-l-bold;
      border: none;
      border-bottom: 1px solid var(--navigationColor);
    }

    button {
      margin: 15px auto;
    }
  }
}

@mixin panel {
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid transparent;

  p {
    margin: 10px;
    font: $font-m-bold;
  }

  button {
    .icon {
      margin: auto 10px;
    }
  }
}

@mixin list {
  max-height: 200px;
  overflow: auto;

  .list {
    display: flex;
    height: auto;
    margin: auto 10px;

    img {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      margin: 5px;
      border-radius: 50%;
    }

    .primary {
      margin: 5px 10px 0 10px;
      font: $font-m;
      cursor: pointer;
    }

    .secondary {
      margin: 5px 10px;
      font: $font-xs-italic;
    }

    button {
      margin-left: auto;
    }
  }
}

@mixin tiles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 5px;

  div,
  img {
    width: 46px;
    height: 46px;
    margin: 5px;
    border: 1px solid var(--navigationColor);
    cursor: pointer;
  }
}

.sidebar-left-closed {
  position: fixed;
  left: -300px;
  z-index: -10;
}

.sidebar-left-open {
  position: fixed;
  left: 0;
  z-index: 1000;
  box-shadow: 4px 6px 15px $shadow;
  height: inherit;
}

.sidebar-right-closed {
  position: fixed;
  right: -300px;
  z-index: -10;
}

.sidebar-right-open {
  position: fixed;
  right: 0;
  z-index: 1000;
  box-shadow: -4px 0 15px $shadow;
}

.button-text {
  padding: 0 20px 1px 20px;
  color: var(--navigationColor);
  font: $font-s;
  border: 1px solid var(--navigationColor);
  border-radius: 3px;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  font: $font-m;
}

button {
  background: transparent;
  border: none;
  font-family: 'Muli', sans-serif;
  cursor: pointer;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  background: #eeeeeea2 !important;
  z-index: 2000;
}

::-webkit-scrollbar {
  width: 5px;
}

body {
  height: calc(var(--vh, 1vh) * 100);
}

// .root {
//   height: calc(var(--vh, 1vh) * 100 - var(--toolbarHeigh));
// }
.chat-app {
  display: flex;
  height: calc(
    var(--vh, 1vh) * 100 - var(--toolbarHeigh) - var(--frontpageHeight) -
      var(--footerHeight)
  );

  .chat-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: calc(100vh - var(--toolbarHeigh));
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;

    @media (max-width: $medium) {
      width: 100%;
    }

    .topbar {
      display: flex;
      max-height: 55px;
      text-align: center;
      color: var(--navigationColor);
      background-color: var(--background);
      // background-color: rgb(42, 219, 86) 100%;

      .more {
        @media (max-width: $medium) {
          display: inline;
        }

        button {
          margin: 2px 0 0 0px;
        }
      }

      .room-info {
        width: 100%;

        .room-name {
          margin: 5px auto 5px auto;
          font: $font-m-bold;
        }

        .room-users {
          margin: 0 auto 5px auto;
          font: $font-xs-italic;
        }
      }

      .themes {
        button {
          margin: 13px 8px 0 0;
        }
      }
    }

    .messages {
      height: 100%;
      padding: 20px;
      overflow: auto;

      background-color: var(--background-message);

      @media (max-width: $medium) {
        padding: 5px;
      }
    }

    .chat-input {
      padding: 0 1px;
      background: var(--background-gradient);
      box-shadow: 0 -1px 10px $shadow-light;
      bottom: 0;

      form {
        display: flex;
        justify-content: space-between;
        margin: 1px;
        background: var(--background-form);
        border-radius: 6px;
        // border-bottom-left-radius: 0px;
        // border-bottom-right-radius: 0px;
        border: 1px solid transparent;
        background: var(--background-gradient);

        input,
        textarea {
          width: 100%;
          padding: 7px 15px;
          font-size: 16px;
          font-family: 'Muli', sans-serif;
          border: none;
          border-radius: 10px;
        }

        button {
          cursor: pointer;
          display: inline-block;
          min-height: 1em;
          outline: none;
          border: none;
          vertical-align: baseline;
          padding: 0.78571429em 1.5em 0.78571429em;
          font-weight: bold;
          font-size: 80%;
          text-align: center;
          border-radius: 0.28571429rem;
        }
      }
    }
  }
}

.chat-app-mobile {
  display: flex;
  min-height: calc(100vh - var(--toolbarHeigh));
  height: calc(
    var(--vh, 1vh) * 100 - var(--toolbarHeigh) - var(--frontpageHeight)
  );
  position: fixed;
  width: 100%;

  .chat-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: calc(100vh - var(--toolbarHeigh));
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;

    @media (max-width: $medium) {
      width: 100%;
    }

    .topbar {
      display: flex;
      max-height: 55px;
      text-align: center;
      color: var(--navigationColor);
      background-color: var(--background);

      // background-color: rgb(42, 219, 86) 100%;

      .more {
        @media (max-width: $medium) {
          display: inline;
        }

        button {
          margin: 2px 0 0 0px;
        }
      }

      .room-info {
        width: 100%;

        .room-name {
          margin: 5px auto 5px auto;
          font: $font-m-bold;
        }

        .room-users {
          margin: 0 auto 5px auto;
          font: $font-xs-italic;
        }
      }

      .themes {
        button {
          margin: 13px 8px 0 0;
        }
      }
    }

    .messages {
      height: 100%;
      padding: 20px;
      overflow: auto;
      background-color: var(--background-message);

      @media (max-width: $medium) {
        padding: 5px;
      }
    }

    .chat-input {
      padding: 0 1px;
      background: var(--background-gradient);
      box-shadow: 0 -1px 10px $shadow-light;

      form {
        display: flex;
        justify-content: space-between;
        margin: 1px;
        background: var(--background-message);
        border-radius: 6px;
        // border-bottom-left-radius: 0px;
        // border-bottom-right-radius: 0px;
        border: 1px solid transparent;
        background: var(--background-gradient);

        input,
        textarea {
          width: 90%;
          padding: 7px 15px;
          font-size: 16px;
          font-family: 'Muli', sans-serif;
          border: none;
          border-radius: 10px;
        }

        button {
          cursor: pointer;
          display: inline-block;
          min-height: 1em;
          outline: none;
          border: none;
          vertical-align: baseline;
          padding: 0.78571429em 1.5em 0.78571429em;
          font-weight: bold;
          font-size: 80%;
          text-align: center;
          border-radius: 0.28571429rem;
        }
      }
    }
  }
}

.create-room-modal {
  @include modal;
}

.login-page {
  min-height: 100vh;
  margin-top: -1px;
  border-top: 1px solid transparent;

  .login-modal {
    @include modal;
  }
}

.logout {
  a {
    color: var(--navigationColor) !important;
    font-size: 14px;
    float: right;
  }
}

.sidebar-left {
  min-width: 200px;
  max-width: 300px;
  // min-height: 100vh;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;

  .profile {
    display: flex;
    justify-content: space-between;
    height: 90px;

    .left {
      display: flex;

      img {
        min-width: 70px;
        max-width: 70px;
        min-height: 70px;
        max-height: 70px;
        margin: 10px;
        border-radius: 50%;
      }

      p {
        margin: 15px;
        font: $font-l-bold;
      }

      .edit {
        margin-left: 15px;
      }
    }

    .close {
      display: none;

      @media (max-width: $medium) {
        display: inline;
      }

      button {
        margin: 10px 5px;
      }
    }
  }

  .public-chats {
    .panel {
      @include panel;
    }

    .public-chats-list {
      @include list;
    }
  }

  .users {
    .panel {
      @include panel;
    }

    .users-list {
      @include list;
    }
  }
}

.sidebar-right {
  min-width: 300px;
  max-width: 300px;
  min-height: 100vh;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;

  .close {
    button {
      margin: 10px 5px;
    }
  }

  .themes {
    .panel {
      @include panel;
    }

    .colors {
      @include tiles;
    }
  }

  .backgrounds {
    .panel {
      @include panel;
    }

    .images {
      @include tiles;
    }
  }
}

.message {
  display: flex;
  max-width: 600px;
  height: auto;

  // @media (max-width: $medium) {
  //   max-width: 300px;
  // }

  // img {
  //   min-width: 50px;
  //   max-width: 50px;
  //   min-height: 50px;
  //   max-height: 50px;
  //   margin: 5px;
  //   border-radius: 50%;
  //   box-shadow: 1px 1px 3px #808080ab;

  //   @media (max-width: $medium) {
  //     min-width: 40px;
  //     max-width: 40px;
  //     min-height: 40px;
  //     max-height: 40px;
  //   }
  // }

  .message-content {
    min-width: 100px;
    height: auto;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 6px;
    box-shadow: 1px 1px 3px #8080808c;
    overflow: hidden;

    .sender {
      margin: 0 0 10px 0;
      font: $font-xs-bold;
    }

    .text {
      margin: 0;
      font: $font-m;
    }

    .time {
      float: right;
      margin: 5px 0 0 0;
      font: $font-xs-italic;
    }
  }

  .message-content-consecutive {
    @extend .message-content;
    margin-left: 65px;

    @media (max-width: $medium) {
      margin-left: 55px;
    }
  }
}

.message__mobile {
  display: flex;
  max-width: 600px;
  height: auto;
  margin-top: 6rem;

  .message-content {
    min-width: 100px;
    height: auto;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 6px;
    box-shadow: 1px 1px 3px #8080808c;
    overflow: hidden;

    .sender {
      margin: 0 0 10px 0;
      font: $font-xs-bold;
    }

    .text {
      margin: 0;
      font: $font-m;
    }

    .time {
      float: right;
      margin: 5px 0 0 0;
      font: $font-xs-italic;
    }
  }

  .message-content-consecutive {
    @extend .message-content;
    margin-left: 65px;

    @media (max-width: $medium) {
      margin-left: 55px;
    }
  }
}

.myMessage {
  margin-left: auto;
  justify-content: flex-end;

  .message-content {
    text-align: right;

    .time {
      float: left;
    }
  }

  .message-content-consecutive {
    @extend .message-content;
    margin-right: 65px;

    @media (max-width: $medium) {
      margin-right: 55px;
    }
  }
}

.password-modal {
  @include modal;
}

.avatar-modal {
  @include modal;

  img {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
    margin: 0 auto 30px auto;
    border-radius: 50%;
  }

  form {
    input {
      font: $font-s;
    }
  }
}

@mixin theme(
  $primary,
  $primary-transparent,
  $primary-light,
  $secondary,
  $input-outline,
  $icon,
  $send-icon,
  $error,
  $my-message,
  $message,
  $sender,
  $message-text,
  $message-time,
  $scroll-track,
  $scroll-thumb
) {
  ::-webkit-scrollbar-track {
    background: $scroll-track;
  }

  .button-text {
    color: $secondary;
    border-color: $secondary;
  }

  .chat-app {
    .sidebar-left {
      background: $primary-transparent;
      color: $secondary;

      .profile {
        .close {
          .icon {
            color: $icon;
          }
        }
      }

      .public-chats {
        .panel {
          background: $primary;
          color: $secondary;

          .icon {
            color: $icon;
          }
        }

        .public-chats-list {
          .list {
            border-color: $primary-light;

            .icon {
              color: $icon;
            }
          }
        }
      }

      .users {
        .panel {
          background: $primary;
          color: $secondary;
        }

        .users-list {
          .list {
            border-color: $primary-light;
          }
        }
      }
    }

    .chat-content {
      .topbar {
        background: $primary;
        color: $secondary;

        .more {
          button {
            .icon {
              color: $icon;
            }
          }
        }

        .themes {
          button {
            .icon {
              color: $icon;
            }
          }
        }
      }

      .messages {
        .my-message {
          .message-content,
          .message-content-consecutive {
            background: $my-message;

            .sender {
              color: $sender;
            }

            .time {
              color: $message-time;
            }
          }
        }

        .message {
          .message-content,
          .message-content-consecutive {
            background: $message;

            .sender {
              color: $sender;
            }

            .time {
              color: $message-time;
            }
          }
        }
      }

      .chat-input {
        form {
          border-color: $input-outline;

          button {
            .send-icon {
              color: $send-icon;
            }
          }
        }
      }
    }

    .sidebar-right {
      background: $primary-transparent;
      color: $secondary;

      .close {
        button {
          .icon {
            color: $icon;
          }
        }
      }

      .themes {
        .panel {
          background: $primary;
          color: $secondary;
        }
      }

      .backgrounds {
        .panel {
          background: $primary;
          color: $secondary;
        }
      }
    }
  }

  .avatar-modal,
  .create-room-modal,
  .password-modal,
  .login-modal {
    background: $primary;
    color: $secondary;

    form {
      .error {
        color: $error;
      }

      input {
        color: $secondary;
        border-color: $secondary;
      }
    }
  }
}
// dark
// @include theme(
// /* $primary             */ #416577ad,
// /* $primary-transparent */ unquote('#263238f1'),
// /* $primary-light       */ #475a64,
// /* $secondary           */ #fff,
// /* $input-outline       */ #c5c5c5,
// /* $icon                */ #fff,
// /* $send-icon           */ #00bcd4,
// /* $error               */ #ffd752,
// /* $my-message          */ #c0e2e7,
// /* $message             */ #ffffff,
// /* $sender              */ #00bcd4,
// /* $message-text        */ #000,
// /* $message-time        */ gray,
// /* $scroll-track        */ #81ccd6,
// /* $scroll-thumb        */ #37c1d3
// );
// light
// @include theme(
// /* $primary             */ #dedef7,
// /* $primary-transparent */ unquote('#eaeafde7'),
// /* $primary-light       */ #dedef7,
// /* $secondary           */ #4c4c5c,
// /* $input-outline       */ #c5c5c5,
// /* $icon                */ #ff8cb8,
// /* $send-icon           */ #ff8cb8,
// /* $error               */ #ff2579,
// /* $my-message          */ #c0e2e7,
// /* $message             */ #ffffff,
// /* $sender              */ #fd5d9b,
// /* $message-text        */ #000,
// /* $message-time        */ gray,
// /* $scroll-track        */ #ffc7dd,
// /* $scroll-thumb        */ #ff8cb8
// );
// violet
// @include theme(
// /* $primary             */ var(--sideNavigationBackgroundNewBrowsers),
// /* $primary-transparent */ unquote('#6c65ace0'),
// /* $primary-light       */ #9087e0,
// /* $secondary           */ #fff,
// /* $input-outline       */ #c5c5c5,
// /* $icon                */ #fff,
// /* $send-icon           */ #746db4,
// /* $error               */ #ffd752,
// /* $my-message          */ #c0e2e7,
// /* $message             */ #ffffff,
// /* $sender              */ #746db4,
// /* $message-text        */ #000,
// /* $message-time        */ gray,
// /* $scroll-track        */ #b0abcf,
// /* $scroll-thumb        */ #7068b4
// );
