/*
Fonts
*/

// $font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
$font-family: 'Segoe UI', 'Lucida Grande', Arial, sans-serif;
$font-family-alt: 'Open Sans', 'Helvetica Neue' Helvetica, Arial, sans-serif;

/*
Mixins
*/

@mixin translateY($e) {
  -webkit-transform: translateY($e);
  -moz-transform: translateY($e);
  transform: translateY($e);
}

@mixin transition($e) {
  -webkit-transition: $e;
  -moz-transition: $e;
  transition: $e;
}

// CSS Variables

:root {
  --link-color: #d45605;
  // --link-color: red;
  --toolbarHeigh: 56px;
  --navigationColor: #ffff;
  --navigationColorActive: #ffff;
  --navigationBorderColorActive: #d45605;
  --navigationColorHover: #000000;
  --navigationButtonColor: #d45605;
  --frontpageHeight: 270px;
  --backgroundHeight: 250px;
  --footerHeight: 50px;
  --spaceHeight: 50px;
  --border: #d45605;
  --boxShadow: #f8ba7a;
  --rce-mbox-professional: #f8ba7a;
  --background: #d45605;
  --background-gradient: linear-gradient(
    to bottom,
    rgba(254, 157, 5, 100),
    rgba(250, 114, 25, 100)
  );
  --background-message: #ffff;
  --background-form: #ffff;
  --navigationBackgroundOldBrowsers: #d45605;
  --navigationBackgroundOldFF: -moz-linear-gradient(
    45deg,
    #e6c288 0%,
    #fe9d05 52%,
    #fa7219 100%
  ); /* FF3.6-15 */
  --navigationBackgroundOldChrome: -webkit-linear-gradient(
    45deg,
    #e6c288 0%,
    #fe9d05 52%,
    #fa7219 100%
  ); /* Chrome10-25,Safari5.1-6 */
  --navigationBackgroundNewBrowsers: linear-gradient(
    45deg,
    #e6c288 0%,
    #fe9d05 52%,
    #fa7219 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  --black: #010000;
  --white: #ffffff;
  --lightGrey: #e6e6e6;
  --darkGrey: #333;
  --red: #ff1755;
  --blue: #1f53cb;
  --btngradient: #fe9d05 0%, #fa7219 100%;
  --activegradient: linear-gradient(to top, #fe9d05 0%, #fa7219 100%);
  --font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-family-alt: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-size: 1.3rem;
  --font-size-sm: 1.1rem;
  --gray-base: #000 ' !default';
  --gray-darker: lighten($gray-base, 13.5%) ' !default'; // #222
  --gray-dark: lighten($gray-base, 20%) ' !default';
  // #333
  /* Chat */
}
