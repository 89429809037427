.rce-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
}

.rce-navbar.light {
    background: #f4f4f4;
}

.rce-navbar.dark {
    background: #2f414c;
}

.rce-navbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.rce-navbar-item > * {
    display: flex;
    flex-direction: row;
}

.rce-navbar-item > * > * {
    margin-left: 5px;
}