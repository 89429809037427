$paddingLeft: 10px;

.ChannelWrapper {
  width: 200px;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,2989d8+52,7db9e8+100 */
  // background: #00afdc;
  background: #FA7219;
  background: -moz-linear-gradient(top, 
  #FA7219 0%,
  #FE9D05 52%,
  #E6C288 100%);
  background: -webkit-linear-gradient(
    top,
    #FA7219 0%,
    #FE9D05 52%,
    #E6C288 100%
  );
  background: linear-gradient(to bottom, 
  #E6C288 0%,
  #FE9D05 52%,
  #FA7219 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 );
  color: #fff;

  .TeamNameHeader {
    color: #fff;
    font-size: 20px;
  }

  .SideBarList {
    width: 100%;
    list-style: none;
    padding-left: 0px;

    .SideBarListHeader {
      padding-left: $paddingLeft;
    }

    .SideBarListItem {
      padding: 2px;
      padding-left: $paddingLeft;

      &:hover {
        background: #D45605;
      }
      color: rgb(255, 255, 255);

      &:hover {
        color: rgb(255, 255, 255);
      }
    }
  }

  .PushLeft {
    padding-left: $paddingLeft;

    a {
      color: #D45605;
      font-weight: 600;
    }
  }
}
