.side-drawer {
  // height: calc(100% - var(--toolbarHeigh));
  height: 100%;
  background: #fff;
  // box-shadow: 2px 0px 7px rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  max-width: 768px;
  z-index: 200;
  transform: translateX(-102%);
  transition: transform 0.3s ease-out;

  &.open {
    transform: translateX(0);
    z-index: 2000;
  }

  ul {
    height: calc(100% - 30px);
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline-start: 0;
    padding-inline-end: 0;

    .linkButton {
      background: #D45605; /* Old browsers */
      background: -moz-linear-gradient(
        top,
        #FA7219 0%,
        #FE9D05 52%,
        #E6C288 100%
      );

      /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        #FA7219 0%,
        #FE9D05 52%,
        #E6C288 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        #E6C288 0%,
        #FE9D05 52%,
        #FA7219 100%
      );

      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      width: 80%;
      min-width: fit-content;
      max-width: 300px;
      padding-inline-start: 20px;
      padding-inline-end: 20px;
      padding-block-end: 10px;
      padding-block-start: 10px;
      border-radius: 20px;
      margin: 10px;
      min-height: fit-content;

      &:active {
        box-shadow: 0 5px
          linear-gradient(
            to bottom,
            rgba(92, 128, 45, 100) 0%,
            rgba(85, 129, 26, 100) 50%,
            rgba(72, 88, 65, 100) 100%
          );
        transform: translateY(4px);
      }
    }
  }

  li {
    display: flex;
    justify-content: center;
    padding: 5px;
    margin: 5px;

    .linkButton {
      background: #D45605; /* Old browsers */
      background: -moz-linear-gradient(
        top,
        #FA7219 0%,
        #FE9D05 52%,
        #E6C288 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        #FA7219 0%,
        #FE9D05 52%,
        #E6C288 100%
      );

      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        #E6C288 0%,
        #FE9D05 52%,
        #FA7219 100%
      );

      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      width: 80%;
      min-width: fit-content;
      max-width: 300px;
      padding-inline-start: 20px;
      padding-inline-end: 20px;
      padding-block-end: 10px;
      padding-block-start: 10px;
      border-radius: 20px;
      margin: 10px;
      min-height: fit-content;

      &:active {
        box-shadow: 0 5px
          linear-gradient(
            to bottom,
            rgba(92, 128, 45, 100) 0%,
            rgba(85, 129, 26, 100) 50%,
            rgba(72, 88, 65, 100) 100%
          );
        transform: translateY(4px);
      }
    }
  }

  a {
    color: var(--navigationButtonColor);
    text-decoration: none;
    font-size: 13px;
    padding: 10px;


    &:hover,
    &:active {
      color: rgba($color: #F8BA7A, $alpha: 0.5);
    }


  }
}
@media (min-width: 769px) {
  .side-drawer {
    display: none;
  }

  .changeLanguageFlagsSideBar {
    display: none;
  }
}
@media (max-height: 660px) {
  .side-drawer {
    li {
      padding: 0px;
      margin: 0px;
    }
  }
}
