.rce-dropdown-container {
    position: relative;
}
.rce-dropdown {
    min-width: 100%;
    box-sizing: border-box;
    padding: 8px 15px;
    background: #fff;
    border-radius: 5px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    position: absolute;
    box-shadow: 0px 0px 5px 0px rgba(163, 163, 163, 1);
    transform-origin: left top;
    z-index: 99999;
}

.rce-dropdown.dropdown-show{
    animation: dropdown-scaling 0.2s ease forwards;
    display: flex;
}

@keyframes dropdown-scaling {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.rce-dropdown.dropdown-hide{
    animation: dropdown-reverse-scaling 0.2s ease forwards;
    display: flex;
}

@keyframes dropdown-reverse-scaling {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0.5;
        transform: scale(1.1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}

.rce-dropdown-open__nortwest {
    transform-origin: left top;
    left: 0;
    top: 100%;
    margin-top: 5px
}

.rce-dropdown-open__norteast {
    transform-origin: right top;
    right: 0;
    top: 100%;
    margin-top: 5px
}

.rce-dropdown-open__southwest {
    transform-origin: left bottom;
    left: 0;
    bottom: 100%;
    margin-bottom: 5px
}

.rce-dropdown-open__southeast {
    transform-origin: right bottom;
    right: 0;
    bottom: 100%;
    margin-bottom: 5px
}

.rce-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rce-dropdown ul li {
    white-space: nowrap;
    color: #767676;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    box-sizing: border-box;
    user-select: none;
}

.rce-dropdown ul li:last-child {
    border: none;
}

.rce-dropdown ul li:hover a {
    color: #3a6d8c;
}

.rce-dropdown.fade {
    opacity: 0;
    transform: scale(1);
    animation: dropdown-fade 0.5s ease forwards;
}

@keyframes dropdown-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
