.toolbar {
  width: 100%;
  height: var(--toolbarHeigh);
  z-index: 1000;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,2989d8+52,7db9e8+100 */
  background: #D45605; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(99,173,0,100),
    rgba(72,88,65,100)
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(99,173,0,100),
    rgba(72,88,65,100)
  ); /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(to bottom, rgba(254,157,5,100), rgba(250,114,25,100));
}

.toolbar__mobile{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--toolbarHeigh);
  z-index: 1000;
  
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,2989d8+52,7db9e8+100 */
  background: #D45605; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(99,173,0,100),
    rgba(72,88,65,100)
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(99,173,0,100),
    rgba(72,88,65,100)
  ); /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(to bottom, rgba(254,157,5,100), rgba(250,114,25,100));
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
}

.toolbar__pagename {
  color: var(--navigationColor);
  font-size: 1rem;
  font-weight: bold;
  display: none;
  text-align: center;

}

.toolbar_navigation-items {
  display: flex;
  flex: 1;
  justify-content: center;

  ul {
    list-style: none;
    display: flex;
    align-content: space-between;
    align-items: center;
    width: 100%;
  }

li{
  height: 40px;

}

.affix {
  top: 0;
  width: 100%;
  z-index: 9999 !important;
}

.affix + .container-fluid {
  padding-top: 70px;
}

  a {
    text-decoration: none;
    color: var(--navigationColor);
    width: 100%;
    padding:  0 2rem;
    border-bottom: 5px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--toolbarHeigh);
    margin-top: -10px;


    &:hover{
      border-bottom-color: white;
      color: var(--navigationColorActive)
    }
  }

  .active {
    color: var(--navigationColorActive);
    border-bottom-color: white;
  }
}

.nav__mobile{
    display: grid;
    margin-left: auto;
    margin-right: auto;
    color: black;
    text-align: center;
    font-weight: bold;
  }

@media (max-width: 768px) {
  .toolbar_navigation-items {
    display: none;
  }

  .toolbar__pagename {
    display: block;
    font-size: 1.7rem;
  }

  .toolbar__navigation .changeLanguageFlags {
    display: none;
  }
}
@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }
}


.toolbar__navigation__middle {
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  margin-left: -2rem;
}

.toolbar__navigation__right {
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  width: 10%;
  // margin-left: 2rem;

  img {
    margin-left: 5px;
    cursor: pointer;
    // width: 100%;
  }
}

.toolbar__navigation__left {
  width: -10%;
  flex-shrink: 1;
  display: flex;
}
@media (max-width: 960px) {
  .toolbar{
    display: flex;
    justify-content: center;
  }
  .toolbar__navigation__middle {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    font-size: 1rem;
  }
  
  .toolbar__navigation__right {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    width: 10%;
    // margin-left: 2rem;
  
    img {
      margin-left: 5px;
      cursor: pointer;
      // width: 100%;
    }
  }
}